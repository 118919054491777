<template>
  <div>
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              class="mr-2"
              @click="addBooking()"
            >
              + Tambah Data Booking
            </b-button>
            <b-button
              variant="primary"
              @click="exportBookingModal = true"
            >
              Export Data
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <v-select
                v-model="bisFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="bisOptions"
                class="invoice-filter-select mr-1"
                placeholder="Select Bis"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                class="invoice-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="rows"
        responsive
        :fields="columns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchQuery"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder">#{{ data.value }}</span>
        </template>

        <!-- Column: Harga -->
        <template #cell(harga)="data">
          <span class="text-primary font-weight-bolder">{{ data.value | formatRupiah }}</span>
        </template>

        <!-- Column: Id -->
        <template #cell(booking)="data">
          {{ data.item.tanggal_berangkat }} - {{ data.item.tanggal_balik }}
        </template>

        <!-- Column: Status -->
        <template #cell(status_booking)="data">
          <b-button :variant="data.value === 'selesai' ? 'relief-success' : (data.value === 'perjalanan' ? 'relief-warning' : 'relief-danger')">
            {{ data.value === 'selesai' ? 'Selesai' : (data.value === 'perjalanan' ? 'Perjalanan' : 'Belum Berjalan') }}
          </b-button>
        </template>

        <!-- Column: Status -->
        <template #cell(status_pembayaran)="data">
          <b-button
            class="text-capitalize"
            :variant="data.value === 'lunas' ? 'relief-success' : (data.value === 'dp' ? 'relief-warning' : 'relief-danger')"
          >
            {{ data.value }}
          </b-button>
        </template>

        <!-- Column: Actions -->
        <template #cell(action)="data">

          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="data.item.status_booking !== 'selesai'"
                :to="{ name: 'edit-booking', params: { id: data.item.id } }"
              >
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item :to="{ name: 'preview-booking', params: { id: data.item.id } }">
                <span class="align-middle ml-50">Cetak</span>
              </b-dropdown-item> -->
              <b-dropdown-item @click="previewCetak(data.item)">
                <span class="align-middle ml-50">Cetak</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status_pembayaran !== 'lunas'"
                @click="removeData(data.item.id)"
              >
                <span class="align-middle ml-50">Hapus</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status_pembayaran !== 'lunas'"
                @click="detailPelunasan(data.item)"
              >
                <span class="align-middle ml-50">Pelunasan</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          <!-- <span class="text-muted">Showing entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalBooking"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-cetal"
      v-model="modalCetak"
      ok-only
      ok-title="Close"
      centered
      size="xl"
      title="Booking Invoice"
    >
      <preview-cetak
        :booking="dataBookingPreview"
      />
    </b-modal>
    <b-modal
      v-model="modalPelunasan"
      title="Detail Data Booking"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @hidden="resetModal"
      @ok="pelunasanBooking(dataDetailPelunasan)"
    >
      <b-row>
        <b-col
          cols="12"
          class="d-flex"
        >
          <label
            class="mr-1"
          >
            Nama Customer:
          </label>
          <h6> {{ dataDetailPelunasan.customer }}</h6>
        </b-col>
        <b-col
          cols="12"
          class="d-flex"
        >
          <label
            class="mr-1"
          >
            Bis:
          </label>
          <h6> {{ dataDetailPelunasan.bis.nama }}</h6>
        </b-col>
        <b-col
          cols="12"
          class="d-flex"
        >
          <label
            class="mr-1"
          >
            Tanggal Booking:
          </label>
          <h6> {{ dataDetailPelunasan.tanggal_berangkat }} - {{ dataDetailPelunasan.tanggal_balik }}</h6>
        </b-col>
        <b-col
          cols="12"
          class="d-flex"
        >
          <label
            class="mr-1"
          >
            Harga:
          </label>
          <h6> {{ dataDetailPelunasan.harga | formatRupiah }}</h6>
        </b-col>
        <b-col
          cols="12"
          class="d-flex"
        >
          <label
            class="mr-1"
          >
            Kekurangan
          </label>
          <h6> {{ dataDetailPelunasan.harga - dataDetailPelunasan.jumlah_pembayaran_dp | formatRupiah }}</h6>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-1"
      v-model="exportBookingModal"
      title="Export Data"
      centered
      ok-only
      ok-title="Export"
      @ok="exportDataBooking()"
      @close="resetExportData()"
    >
      <b-row>
        <b-col
          cols="12"
          class="d-flex mb-1"
        >
          <label
            class="mr-1"
          >Tahun: </label>
          <date-picker
            v-model="exportData.tahun"
            value-type="format"
            format="YYYY"
            type="year"
            placeholder="Pilih Tahun"
          />
        </b-col>
        <b-col
          cols="12"
          class="d-flex"
        >
          <label
            class="mr-1"
          >Bulan: </label>
          <date-picker
            v-model="exportData.bulan"
            value-type="format"
            format="MM"
            type="month"
            placeholder="Pilih Bulan"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BDropdown, BDropdownItem, BPagination, BModal,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store/index'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import previewCetak from './Preview.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,

    previewCetak,
    vSelect,
    DatePicker,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'ID',
          key: 'id',
          sortable: true,
        },
        {
          label: 'Customer',
          key: 'customer',
          sortable: true,
        },
        {
          label: 'Bis',
          key: 'bis.nama',
          sortable: true,
        },
        {
          label: 'Hari Booking',
          key: 'booking',
          sortable: true,
        },
        {
          label: 'Total Harga',
          key: 'harga',
          sortable: true,
        },
        {
          label: 'Pembayaran',
          key: 'status_pembayaran',
          sortable: true,
        },
        {
          label: 'Status',
          key: 'status_booking',
        },
        {
          label: 'Action',
          key: 'action',
        },
      ],
      perPage: 5,
      totalBooking: 0,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      bisFilter: null,
      rowsData: [],
      searchTerm: '',
      statusOptions: [
        'Selesai',
        'Proses',
        'Belum',
      ],
      addBookingSidebar: false,
      bisOptions: [],
      modalCetak: false,
      dataBookingPreview: {},
      modalPelunasan: false,
      dataDetailPelunasan: {
        id: null,
        customer: null,
        tanggal_balik: null,
        tanggal_berangkat: null,
        harga: null,
        jumlah_pembayaran_dp: null,
        bis: {
          nama: null,
        },
      },
      exportBookingModal: false,
      exportData: {
        tahun: null,
        bulan: null,
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    rows() {
      return this.rowsData.filter(data => {
        if (this.statusFilter !== null && this.bisFilter !== null) {
          return data.status === this.statusFilter && data.bis === this.bisFilter
        } if (this.statusFilter !== null) {
          return data.status === this.statusFilter
        } if (this.bisFilter !== null) {
          return data.bis === this.bisFilter
        }
        return true // Return true if no filters are applied
      })
    },
  },
  created() {
    this.getData()
    this.getBus()
  },
  methods: {
    getData() {
      this.$http.get('/booking').then(response => {
        if (response.data.status === true) {
          this.rowsData = response.data.data
          this.totalBooking = response.data.data.length
        }
      })
    },
    getBus() {
      this.$http.get('/bis').then(response => {
        if (response.data.status === true) {
          response.data.data.forEach(data => {
            const item = data.nama
            this.bisOptions.push(item)
          })
        }
      })
    },
    addBooking() {
      this.$swal({
        title: 'Data Konfirmasi',
        text: 'Pilih Sesuai Kebutuhan',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Bis',
        cancelButtonText: 'Tanggal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$router.push({ name: 'add-booking', params: { type: 'bis' } })
        } else if (result.dismiss === 'cancel') {
          this.$router.push({ name: 'add-booking', params: { type: 'tanggal' } })
        }
      })
    },
    detailPelunasan(data) {
      this.dataDetailPelunasan = data
      this.modalPelunasan = true
    },
    resetModal() {
      this.dataDetailPelunasan = {
        id: null,
        customer: null,
        tanggal_balik: null,
        tanggal_berangkat: null,
        harga: null,
        jumlah_pembayaran_dp: null,
        bis: {
          nama: null,
        },
      }
    },
    pelunasanBooking(item) {
      this.$swal({
        title: 'Data Konfirmasi',
        text: 'Apakah Anda Ingin Melakukan Pelunasan?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Benar',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const data = {
            jumlah: item.jumlah_pembayaran_dp ? item.harga - item.jumlah_pembayaran_dp : item.harga,
          }
          this.$http.post(`/booking-pelunasan/${item.id}`, data).then(response => {
            if (response.data.status === true) {
              this.$swal({
                icon: 'success',
                title: 'Pembayaran Berhasil!',
                text: 'Data tercatat sudah lunas!.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getData()
            }
          })
          this.getData()
        }
      })
    },
    removeData(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/booking/${id}`).then(response => {
            if (response.data.status === true) {
              this.$swal({
                icon: 'success',
                title: 'Berhasil Dihapus!',
                text: 'Data Booking berhasil dihapus.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getData()
            }
          })
        }
      })
    },
    previewCetak(data) {
      this.modalCetak = true
      this.dataBookingPreview = data
    },
    resetExportData() {
      this.exportData = {
        tahun: null,
        bulan: null,
      }
    },
    exportDataBooking() {
      this.$http.get(`booking-export?tahun=${this.exportData.tahun}&bulan=${this.exportData.bulan ? this.exportData.bulan : ''}`, {
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'ExportBooking.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
</style>
